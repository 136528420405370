.DateRangePicker {
  display: block !important;
}
.DateInput {
  background: rgba(255, 255, 255, 0);
}

.SingleDatePicker {
  display: block !important;
}

.DateRangePicker_picker {
  z-index: 999;
}
.SingleDatePicker_picker {
  z-index: 999;
}

.DateInput_input {
  line-height: 35px;
  color: #000000;
}
.DateRangePickerInput {
  background: rgba(255, 255, 255, 0);
}
.SingleDatePickerInput {
  background: rgba(255, 255, 255, 0);
}
.DateRangePickerInput__withBorder {
  border: 0;
}
.SingleDatePickerInput__withBorder {
  border: 0;
}
.theme-search-area-form {
  z-index: 999;
  border: 0 !important;
  padding-top: 20px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: rgba(0, 162, 229, 0.5);

  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
  border-top-right-radius: 15px;
}
.theme-search-area input {
  background: rgba(255, 255, 255, 0);
}
.DateRangePickerInput_calendarIcon_svg {
  fill: #000000;
  width: 20px;
  height: 20px;
}
.DateRangePickerInput_arrow_svg {
  fill: #000000;
  width: 22px;
  height: 22px;
}
.SingleDatePickerInput_calendarIcon_svg {
  fill: #000000;
  width: 20px;
  height: 20px;
}
.SingleDatePickerInput_arrow_svg {
  fill: #000000;
  width: 22px;
  height: 22px;
}

.theme-search-area input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #000000;

  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=75)";
  filter: alpha(opacity=75);
}

.theme-search-area input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #000000;
  opacity: 0.75;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=75)";
  filter: alpha(opacity=75);
}

.theme-search-area input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #000000;
  opacity: 0.75;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=75)";
  filter: alpha(opacity=75);
}

.divBack1500 {
  background: url("/img/bg/default.jpg") no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  /*background-image: url('/img/1500x801xlow_534bfbd7eef0ed71e989798f09110e6b.jpg')*/
}
.center {
  margin: 0 auto;
  text-align: center;
}
.divBack350 {
  background-image: url("/img/350x260.png");
}

.divBack350x435 {
  background-image: url("/img/350x435.png");
}

.divBack350x260 {
  background-image: url("/img/350x260.png");
}
.ant-select-selection {
  background-color: transparent !important;
}
.ant-select-auto-complete.ant-select .ant-input {
  display: block !important;
  width: 100% !important;
  border: none !important;
  outline: none !important;
  height: 55px !important;
  font-size: 16px !important;
  border-radius: 5px;
  color: #000 !important;
}
.ant-select-search__field__wrap input {
  background-color: #ffffff;
}
.ant-select-auto-complete.ant-select .ant-select-selection__rendered {
  height: 55px !important;
  color: #fff !important;
  font-size: 18px !important;
}
.ant-select-selection__placeholder {
  color: #000000 !important;
  padding-left: 42px !important;
  font-size: 16px !important;
}
.ant-select-dropdown-menu {
  max-height: 450px;
}
.ant-input {
  padding-left: 42px !important;
}
.ant-select-dropdown-menu-item {
  border-bottom: 1px solid #e6e6e6;
  padding: 12px 20px;
}
.ant-select-dropdown-menu-item:hover {
  background: #0093d2 !important;
  color: #fff !important;
}
.ant-select-dropdown-menu-item:first-child {
  border-radius: 0 !important;
}
.ant-select-dropdown-menu-item-active {
  background: #ff6c2d !important;
  color: #fff !important;
}
.ant-select {
  width: 100% !important;
}
.btn-primary-inverse {
  background: rgba(0, 162, 229, 0.5); /* #00a2e5!important;*/
  z-index: 996;
  color: #ffffff !important;
  margin-bottom: 0;
  width: 100%;
  height: 60px;
  font-weight: bold;
}

.btn-primary-inverse-active {
  color: #cbcbcb;
  background: rgba(0, 103, 155, 0.5); /*#00679b;*/

  width: 100%;

  z-index: 995;
  font-weight: 100;
}
.btn-primary-inverse-active:hover {
  color: #ffffff;
}
.btn-primary-inverse:hover {
  color: #fff;
}
.theme-coming-soon-form-btn {
  font-size: 20px;

  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.row-height-60 {
  height: 60px;
}
.df:hover {
  -webkit-box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.18);
  box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.18);
}
.df {
  border-radius: 5px;
  -webkit-box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.18);
  box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.18);
  background-color: rgba(0, 0, 0, 0.48) !important;
  color: #ffffff !important;
  font-weight: 100 !important;
  border: 0 transparent thick;
  width: 100%;
  font-size: 1em !important;
}

.df-active {
  border-radius: 5px;
  -webkit-box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.18);
  box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.18);
  background-color: rgba(0, 0, 0, 0.28) !important;
  color: #ffffff !important;
  font-weight: 100 !important;
  border: 0 transparent thick;
  width: 100%;
  font-size: 1em !important;
}
/**/
.banner-baslik_rem {
  padding-top: 5px !important;

  background-color: #00679b;
  padding-left: 15px;
  padding-right: 15px;
}

.closedrem {
  position: absolute;
  right: 0;
  margin: 0px !important;
}

.banner-baslik {
  margin: -15px !important;
  padding-top: 5px !important;
  margin-bottom: 10px !important;
  background-color: #00679b;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 5px !important;
}
.banner-baslik li a {
  margin-top: 5px;
  margin-right: 5px;
  height: 25px;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.35) !important;
}

.border-red {
  border-radius: 5px;
  background: linear-gradient(-45deg, #ff0000, #ffff00);
  background-size: 400% 400%;
  -webkit-animation: Gradient 1s ease infinite;
  -moz-animation: Gradient 1s ease infinite;
  animation: Gradient 1s ease infinite;
}
.border-red .DateRangePickerInput {
  background-color: transparent !important;
  color: #ffffff;
  font-weight: bold;
}

.motion {
  width: 92px;
  height: 85px;
  position: absolute;
  left: 1px;
  top: 1px;

  color: rgba(0, 0, 0, 0.9);
}
.fa-plane_return {
}

.alternate_flight .promotion {
}

.famegafont {
  padding-top: 10px;
  padding-bottom: 10px;

  width: 100%;
  font-size: 22px;

  font-weight: bold;
  text-align: center;
  color: RGBA(255, 255, 255, 1) !important;
}

.fa-plane img {
  width: 42px;
  margin: -10px 20px;
}
.iCheck-helper {
  position: absolute;
  top: 0%;
  left: 0%;
  display: block;
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  background: rgb(255, 255, 255);
  border: 0px;
  opacity: 0;
}

.icheck .checked {
}
.DateRangePickerInput {
  border-radius: 5px;
  -webkit-box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.18);
  box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.18);
  background-color: #ffffff !important;
  color: #000000 !important;
  font-weight: bold;
  border: 1px transparent thick;
  width: 100%;
}

.SingleDatePickerInput {
  border-radius: 5px;
  -webkit-box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.18);
  box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.18);
  background-color: none !important;
  color: #000000 !important;
  font-weight: bold;
  border: 1px transparent thick;
  width: 100% !important;
}

.icon-filter i {
  font-size: 36px;
  width: 100%;
  padding-bottom: 5px;
}
.icon-filter p {
  font-size: 14px;
}

.pull-right {
  float: right !important;
}

.fa svg {
  width: 100%;
  padding-bottom: 92%;
  height: 1px;
  overflow: visible;
}

.bannerlist_baslik h3 {
  margin-top: 10px !important;
  font-size: 1.2em;
}
.bannerlist_content {
  background-color: #ffffff;
  color: #000000;
}
._brl-1 {
  border-left: 1px solid #e6e6e6;
}

._brb-1 {
  border-top: 1px solid #e6e6e6;
  padding-top: 10px !important;
}

.blfd_content {
}
.blfd_banner_price {
  font-family: "Khand", sans-serif;
  z-index: 9999;
  font-size: 39px;
  text-align: center;
  color: #000000;
  margin-right: 12px;
  padding-left: 0px;
  padding-top: 5px;
  line-height: 32px;
  border-bottom: 3px solid #00a2e5;
}
.blfd_banner_tarih {
  font-family: "Khand", sans-serif;
  font-size: 20px;
  color: #00a2e5;
  text-align: left;
  padding-left: 5px;
}
.blfd_caption {
  font-family: "Khand", sans-serif;
  /*font-size:20px;*/
  padding-top: 2px;
  color: #00a2e5;
}
.blfd_airline {
  font-size: 13px;

  color: #ff6c2d;
}
.blfd_content {
  font-size: 12px;
}
.ozel_row {
  border-top: 2px dotted rgba(0, 0, 0, 0.22);
  margin: 5px 5px 5px 5px;
}

.genel_ayrac {
  border-top: 6px dot-dash rgba(0, 0, 0, 0.18);
  margin: 10px 10px 10px 0px !important;
}

.p404_baslik {
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.7);
  font-size: 3.2em;
  padding-bottom: 20px;
}
.p404_icerik {
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.7);
  font-size: 2em;
}
.p404_icerik a {
  color: #54cdff;
}
.divBack404 {
  background-image: url("/img/bg/404_1.jpg");
  /*background-image: url('/img/1500x801xlow_534bfbd7eef0ed71e989798f09110e6b.jpg')*/
}

.banner- {
  height: 400px !important;
}
.text-muted-tel {
  font-size: 2em;
  color: #ffffff;
  text-shadow: 0 2px 4px rgba(255, 255, 255, 0.5);
}

.text-muted-tel {
  font-size: 1.4em;
  color: #ffffff;
  text-shadow: 0 2px 4px rgba(255, 255, 255, 0.5);
}
.text-muted-email a {
  color: #ffffff;
  font-weight: bold;
}

.bg_alternate {
  background-color: #eeeeee;
}

.bg_border_bottom {
  border-bottom: 1px solid #eeeeee;

  padding-bottom: 10px;
}
.bg_border_bottom .icheck {
  margin-top: 0px;
}

.theme-footer- {
  margin-bottom: 1px !important;
}

.bg-grad-w {
  background-color: #ffffff;
}
.bg-grad-r {
  background-color: #ec971f;
}
.fs_tab_baslik {
  font-family: "Khand", sans-serif;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}
.fs_tab_baslik_ters {
  font-family: "Khand", sans-serif;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  margin-bottom: 10px;
}
.fs_baslik {
  color: #ffffff;
  font-size: 1.5em;
  padding-top: 5px;
}
.fs_baslik i {
  width: 30px;
  font-size: 28px;
}
.fs_ileri_geri-left {
  color: #ffffff;
  font-size: 1.2em;
  line-height: 2.5em;
  padding-left: 30px;
  text-shadow: 0 2px 30px rgba(255, 145, 98, 0.65);
}

.fs_ileri_geri-right {
  color: #ffffff;
  font-size: 1.2em;
  line-height: 2.5em;
  text-align: right;
  padding-right: 30px;
  text-shadow: 0 2px 30px rgba(255, 145, 98, 0.65);
}

.fs_ileri_geri-left:hover {
  cursor: pointer;
  text-shadow: 0 2px 4px rgba(255, 255, 255, 0.5);
}

.fs_ileri_geri-right:hover {
  text-shadow: 0 2px 4px rgba(255, 255, 255, 0.5);
  cursor: pointer;
}

.fs_ileri_geri-left {
  background-color: #ff6c2d;
  margin-left: 15px;
  margin-right: -20px;
  border-top-left-radius: 20px;
  text-align: center;
  color: #ffffff;
}

.fs_ileri_geri-right {
  background-color: #ff6c2d;
  margin-right: 10px;
  margin-left: -10px;
  border-top-right-radius: 20px;
  text-align: center;
  color: #ffffff;
}

.border-ters-left {
  border-bottom-left-radius: 20px !important;
  border-top-left-radius: 0 !important;
}

.border-ters-right {
  border-bottom-right-radius: 20px !important;
  border-top-right-radius: 0 !important;
}

.h-fix-150 {
  min-height: 220px;
}

.h-fix-80 {
  min-height: 100px;
}

.font-size-12 {
  font-size: 12px;
}
.font-size-42 {
  font-size: 56px;
}
.whitebold {
  color: #ffffff !important;
  font-weight: bold;
}

.contact_baslik {
  color: #fefd47;
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 15px;
}
.step-fade-white {
  padding-top: 10px;

  background: rgba(255, 255, 255, 0);
  border: 1px solid rgba(255, 255, 255, 0.5);
}

.step-fade-back {
  padding-top: 10px;
  background: rgba(0, 0, 0, 0.4);
}
.icon-font {
  font-family: "Khand", sans-serif !important;
  font-size: 30px !important;
  line-height: 38px;
  height: 40px;
  width: 40px;
}

.icon-font-size {
  font-size: 38px !important;
}

.btn-bg-default {
  background-color: #ff6c2d;
  border: none;
}
.icon-ters-cevir {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.round-trip-ico {
  background: url("/img/roundtrip.png") repeat-x;
  width: 33px;
  height: 33px;
  float: left;
  margin-top: 12px;
  margin-left: 10px;
  margin-right: -20px;
  padding-right: -20px;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.bg-404 {
  background: url("/img/bg/b404.jpg") no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.menu {
  margin-top: 36px;
}
.menu_ust {
  position: absolute;
  right: 0;
  top: 5px;
  height: 30px;
}
.menu_ust li a {
  font-size: 12px;
  height: 25px;
}
.toggle,
[id^="drop"] {
  display: none;
}

/* Giving a background-color to the nav container. */
nav {
  margin: 0;
  padding: 0;
}

#logo {
}

/* Since we'll have the "ul li" "float:left"
 * we need to add a clear after the container. */

nav:after {
  content: "";
  display: table;
  clear: both;
}

/* Removing padding, margin and "list-style" from the "ul",
 * and adding "position:reltive" */
nav ul {
  float: right;
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
}

/* Positioning the navigation items inline */
nav ul li {
  margin: 0px;
  display: inline-block;
  float: left;
  background-color: #ffffff;
  padding: 0px;
  /* padding-left: 15px!important;*/
}

/* Styling the links */
nav a {
  display: block;
  padding: 6px;
  color: #000000;
  font-size: 15px;
  text-decoration: none;
  background-color: #ffffff;
}

nav ul li ul li:hover {
  background: #ffffff;
}

/* Background color change on Hover */
nav a:hover {
  color: #d2d2d2;
  text-decoration: none;
}

/* Hide Dropdowns by Default
 * and giving it a position of absolute */
nav ul ul {
  display: none;
  position: absolute;
  z-index: 999;
  width: 180px;
}

/* Display Dropdowns on Hover */
nav ul li:hover > ul {
  display: inherit;
}

/* Fisrt Tier Dropdown */
nav ul ul li {
  float: none;
  display: list-item;
  position: relative;
  background-color: #ffffff;
}

.remember-item {
  z-index: 1;
  position: relative;
  background: transparent;
  cursor: pointer;
  padding: 2px;
  margin: 2px;
  line-height: 14px;
}

/* Second, Third and more Tiers
 * We move the 2nd and 3rd etc tier dropdowns to the left
 * by the amount of the width of the first tier.
*/
nav ul ul ul li {
  position: relative;
}

.barstyle::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.barstyle::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

.barstyle::-webkit-scrollbar-thumb {
  background-color: #0ae;

  background-image: -webkit-gradient(
    linear,
    0 0,
    0 100%,
    color-stop(0.5, rgba(255, 255, 255, 0.2)),
    color-stop(0.5, transparent),
    to(transparent)
  );
}

.remembersearch {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: 402px;
  min-height: 402px;
  overflow-y: auto !important;
  overflow-x: hidden;
  width: 100%;
  padding: 0;
  margin: 0;
}

li > a:after {
  float: right;
  padding-left: 10px;
  font: normal normal normal 14px/1 FontAwesome;
  content: " \f107";
}
li > a:only-child:after {
  content: "";
}
@media all and (max-width: 1100px) {
  input[type="checkbox"],
  input[type="radio"] {
    box-sizing: border-box;
    padding: 0;
    width: 32px !important;
    height: 32px !important;
  }

  .theme-account-notifications-item .icheck {
    width: 50px !important;
    height: 50px !important;
  }

  .theme-search-results-item-flight-section-path-fly-time > p {
    font-size: 11px !important;
  }

  .private_border {
    padding: 0 0 0 0 !important;
    margin: 0 0 0 0 !important;
    border: none !important;
    box-shadow: none !important;
  }
  .passenger {
    padding: 0 0 0 0 !important;
    border: none !important;
    box-shadow: none !important;
  }

  .nav-center > li {
    display: block;
    padding-bottom: 2px;
  }

  .nav-black > li > a {
    border-radius: 5px !important;
    margin-right: 0;
    text-align: left;
  }

  .btnsearchrow .btn-block {
    font-size: 14px;
  }
  .theme-search-results-item-flight-details-schedule {
    /* border-top:1px solid #e6e6e6;*/
    padding-top: 10px !important;
  }
  .widget_sag_alt {
    position: relative !important;
    width: 100%;
    right: 0 !important;
    top: -20px !important;
    text-align: center;
  }
  .DateInput {
    width: 80% !important;
  }
  #logo {
    display: block;
    padding: 0;
    width: 100%;
    text-align: center;
    float: none;
  }

  nav {
    margin: 0;

    z-index: 9999;
  }

  /* Hide the navigation menu by default */
  /* Also hide the  */
  .toggle + a,
  .menu {
    display: none;
  }

  /* Stylinf the toggle lable */
  .toggle {
    display: block;

    font-weight: 100;
    padding: 6px;

    text-decoration: none;
  }

  .toggle:after {
    float: right;
    font: normal normal normal 26px/1 FontAwesome;
    content: " \f107";
    font-weight: bold;
  }

  .toggle i {
    margin-top: 10px;
    color: #000000;
    font-size: 2.4em;
    float: right;
    text-align: right;
  }
  .toggle i:after {
    content: "" !important;
  }

  .sil:after {
    color: #ffffff;
  }

  .toggle:hover {
    color: #1a1a1a;
  }

  /* Display Dropdown when clicked on Parent Lable */
  [id^="drop"]:checked + ul {
    display: block;
  }

  /* Change menu item's width to 100% */
  nav ul li {
    display: block;
    width: 100%;
    border-bottom: 1px solid #d9d9d9;
  }
  nav ul li:last-child {
    border-bottom: none !important;
  }

  nav ul ul .toggle,
  nav ul ul a {
  }

  nav ul ul ul a {
  }

  nav a:hover,
  nav ul ul ul a {
  }

  nav ul li ul li .toggle,
  nav ul ul a,
  nav ul ul ul a {
  }

  nav ul li ul li .toggle:after {
    float: right;
    font: normal normal normal 14px/1 FontAwesome;
    content: " \f107";
    font-weight: bold;
  }

  nav ul li ul li .toggle,
  nav ul ul a {
  }

  /* Hide Dropdowns by Default */
  nav ul ul {
    float: none;
    position: static;
    width: 100%;
  }

  /* Hide menus on hover */
  nav ul ul li:hover > ul,
  nav ul li:hover > ul {
    display: none;
    width: 100%;
  }

  /* Fisrt Tier Dropdown */
  nav ul ul li {
    width: 100%;
  }

  nav ul ul ul li {
    position: static;
    /* has to be the same number as the "width" of "nav ul ul li" */
  }
}

nav ul ul li {
  border-bottom: 1px solid #d9d9d9;
}

@media all and (max-width: 330px) {
  nav ul li {
    display: block;
    width: 94%;
  }
}

.language_img {
  width: 16px;
  height: 16px;
  margin-right: 10px;
}
.bold {
  font-weight: bold;
}

.DateInput__block {
  width: 100%;
  padding-right: 0px;
  font-size: 10px;
}

.SingleDatePickerInput_calendarIcon {
  position: absolute;
  right: 0;
  padding: 0 0;
  line-height: 20px;
}

.fwimg {
  float: right;
  margin: 20px;
  border: 2px solid #ffffff;
  border-radius: 10px;
}
.flight_banner_etiket {
  font-size: 1.4em;
}
.flight_banner_etiket span {
  color: #ff6c2d;
  font-weight: bold;
}

.flight_banner_etiket_price {
  font-family: "Khand", sans-serif;
  z-index: 9999;
  font-size: 56px;
  line-height: 48px;
  color: #000;
}
.return_icon {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.widget_sag_alt {
  position: absolute;
  right: 25px;
  top: 10px;
}

.checkbox input[type="checkbox"] {
  margin-left: -10px !important;
}
._widget {
  margin-bottom: 10px;
  -moz-border-radius: 12px;
  -webkit-border-radius: 12px;
  border-radius: 12px;
  -moz-box-shadow: 4px 4px 14px #fff;
  -webkit-box-shadow: 4px 4px 14px #fff;
  box-shadow: 4px 4px 14px #fff;
}
._mheight {
  min-height: 600px;
}
.theme-copyright img {
  width: 100%;
  margin: 0;
  padding: 0;
}
._fc_blue span {
  color: #006dae;
}
._font_big {
  font-size: 1.4em;
  color: #fff !important;
  padding: 0 !important;
}
._font_big span {
  color: #fff !important;
}
._f-red {
  color: #ff0000 !important;
}

._f-blue {
  color: #0a00d2 !important;
}

.alternate_flight {
  border-radius: 5px;
  border: none;

  padding-left: 100px;
}

._af-1 {
  background: #ffecda !important;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0, #ffecda),
    color-stop(100%, #ffbb7b)
  ) !important;
  background: -webkit-linear-gradient(top, #ffecda 0, #ffbb7b 100%) !important;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#ffecda),
    to(#ffbb7b)
  ) !important;
  background: linear-gradient(to bottom, #ffecda 0, #ffbb7b 100%) !important;
}

._af-2 {
  background: #f6ed9f !important;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0, #f6ed9f),
    color-stop(100%, #f2df65)
  ) !important;
  background: -webkit-linear-gradient(top, #f6ed9f 0, #f2df65 100%) !important;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#f6ed9f),
    to(#f2df65)
  ) !important;
  background: linear-gradient(to bottom, #f6ed9f 0, #f2df65 100%) !important;
}

._af-3 {
  background: #9f9f9f !important;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0, #d9d9d9),
    color-stop(100%, #cccccb)
  ) !important;
  background: -webkit-linear-gradient(top, #d9d9d9 0, #cccccb 100%) !important;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#d9d9d9),
    to(#cccccb)
  ) !important;
  background: linear-gradient(to bottom, #d9d9d9 0, #cccccb 100%) !important;
}

.normal_flight {
  background: #ffffff;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0, #ffffff),
    color-stop(100%, #fbfbfb)
  );
  background: -webkit-linear-gradient(top, #ffffff 0, #fbfbfb 100%);
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#ffffff),
    to(#fbfbfb)
  );
  background: linear-gradient(to bottom, #ffffff 0, #fbfbfb 100%);
  border-radius: 5px;
  border: none;
  -webkit-box-shadow: 0 1px #fcfcfc, 0 2px rgba(0, 0, 0, 0.15);
  box-shadow: 0 1px #fcfcfc, 0 2px rgba(0, 0, 0, 0.15);
}
.normal_flight .alternate_text {
  display: none;
}

.alternate_flight .alternate_text {
  font-family: "Khand", sans-serif;
  display: block;
  position: absolute;
  width: 80px;
  margin-left: -50px;
  text-align: center;
  font-size: 13px;
  line-height:15px;
}
.alternate_text_img {
  float: left;
  margin-left: -25px;
  width: 20px;
  height: 20px;
  margin-top: 20px;
}

.alternate_flight .theme-search-results-item-flight-section-meta-city {
}

._bn {
  border: none !important;
}

.ressum-sections {
  padding: 15px;
}
.ressum_title-sections {
  background-color: #0093d2;
  border-radius: 8px 8px 0 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-basis: 100%;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  color: #fff;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 10px;
  margin: 0 -5px;
}
.ressum_number {
  text-align: center;
  float: right;

  font-family: "Khand", sans-serif;
  font-size: 30px;
  line-height: 22px;
  background-color: #ffffff;
  color: #0093d2;
  border-radius: 25px;
  width: 30px;
  padding-top: 5px;
}
.ressum_text-title {
  text-align: left;
  float: left;
  font-family: "Khand", sans-serif;
  font-size: 22px;
  width: 100%;
}
.ressum_title-sections {
}
.ressum_body-sections {
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: #ffffff;
}
.ressum_gidis_donus {
  font-family: "Khand", sans-serif;
  font-size: 22px;
  border-bottom: 1px solid #e6e6e6;
  margin-bottom: 5px;
}

.bg-grad-dark {
  background-color: #00a2e5;
}
.bg-grad-ressum {
  background-color: #ffffff;
  color: #000;
  padding-top: 0px;
  padding: 4px;
  margin-top: 10px;
}
.return_banner_text {
  color: #2b2b2b;
}
._bg-tDepartureAll {
  background: #ffffff;
}
._bg-tReturnAll {
  background: #ffffff;
}
.vio_info_text {
  position: absolute;
  top: 18px;
  width: 85%;
  text-align: right;

  font-size: 14px;
}

.reslist_table {
  width: 100%;
  margin-bottom: 20px;
  border: 1px solid #c2c2c2;
}

.reslist_table td {
  padding: 5px;
}

.reslist_table td:first-child {
  font-weight: bold;
  width: 30%;
  border-right: 1px dotted #c2c2c2;
}

.reslist_table tr {
  border-bottom: 1px solid #c2c2c2;
}

.reslist_pas_table {
  width: 100%;

  margin-bottom: 20px;
  border: 1px solid #c2c2c2;
}

.reslist_pas_table td {
  padding: 10px;
  text-align: center;
}

.reslist_pas_table th:first-child {
}

.reslist_pas_table th {
  padding: 10px;
  border-right: 1px solid #c0c0c0;
  text-align: center;
}

.reslist_pas_table tr {
  border-bottom: 1px solid #c2c2c2;
}

.right_box_upc {
  text-align: left;
  font-size: 16px;
  font-family: "Khand", sans-serif;
  padding: 30px;
  padding-bottom: 0 !important;
  border-radius: 10px;
  margin-bottom: 10px;
  color: #ffffff;
}

.right_box_upc .row {
  height: 54px;

  vertical-align: middle;
}

.right_box_upc i {
  padding: 8px;
  width: 32px;
  height: 32px;
  margin-right: 5px;
  margin-bottom: 10px;

  background-color: #ffffff;
  color: #00a2e5;
  border-radius: 50%;
  float: left;
}

.row_border_altkisim {
  border-bottom: 1px dashed #d9d9d9;
}

.steps_genel {
  width: 51px;
  height: 37px;
}
.steps_genel_min {
  min-height: 90px;
}

.check1 {
  background: url("/img/step1.jpg") center center;
}
.check1_ok {
  background: url("/img/step1_over.jpg");
}

.check2 {
  background: url("/img/step2.jpg") center center;
}
.check2_ok {
  background: url("/img/step2_over.jpg");
}

.check3 {
  background: url("/img/step3.jpg") center center;
}
.check3_ok {
  background: url("/img/step3_over.jpg");
}

.check4 {
  background: url("/img/step4.jpg") center center;
}
.check4_ok {
  background: url("/img/step4_over.jpg");
}
.loader .row {
  margin-bottom: 15px;
}
.check-load i {
  color: #5cb85c;
}

.check-load {
  font-size: 1.2em;
}
.pricemotion {
  color: #ff0000;
}

.agentmotion {
  color: #000000;
  display: block;
}

.loader {
  font-family: "Khand", sans-serif;
}
.fontsizemini .theme-search-results-item-price-tag {
  font-size: 1.3em;
}

.theme-search-results-item-price-tagpro {
  display: none;
}
.theme-search-results-item-price-tagpromotion {
  color: #000000;

  font-size: 24px;
  font-family: "Khand", sans-serif;
  text-decoration: line-through;
  padding-top: 4px;
  line-height: 14px;
}
.theme-search-results-item-ico-tagpro {
  display: none;
}

.theme-search-results-item-ico-tagpromotion {
  font-family: "Khand", sans-serif;
  float: left;
  font-size: 14px;
  color: #ff0000;
  line-height: 15px;
}

.passenger {
  width: calc(100% + 20px) !important;

  margin-left: -10px !important;
  margin-bottom: 10px !important;
  padding: 10px;
  border: 3px solid #e8f7fe;
  background-color: #ffffff;
}

.passenger:nth-child(odd) {
  border: 1px solid #ffffff;
  background-color: #e8f7fe;
}
.passenger:nth-child(odd) ._bolme-yap {
  border-top: 1px solid #ffffff;
}

._bolme-yap {
  border: 2px solid #d9edf7;

  margin-bottom: 5px;
  margin-left: 5px;
  margin-right: 5px;
  /* padding: 10px;*/
}
._fleft {
  float: left !important;
}

._fright {
  float: right !important;
}
.sozlesme {
  font-size: 1.4em;
  font-family: "Khand", sans-serif;
  margin: 20px;
  background-color: #d9edf7;
  border: 2px solid #ffffff;
  padding: 15px;
}

._obutton-res {
  font-size: 1.8em;
  width: 80%;
  font-weight: bold;
  font-family: "Khand", sans-serif;
}

.cookie_saved {
  font-family: "Khand", sans-serif;
  background-color: #ffffff;
  color: #787878;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  width: 100%;
  padding: 10px;
  z-index: 9999;
  font-size: 1em;
  border-top: 1px solid #0093d2;
}

.cookie_saved a {
  color: #0093d2;
  font-weight: bold;
}

.cookie_saved .btn {
  color: #ffffff;
  margin-left: 25px;
}

.private_border {
  border-top: 1px solid #cfe1e9;
  border-bottom: 1px solid #cfe1e9;
  margin-bottom: 15px;
  margin-left: 5px;
  margin-right: 5px;
  padding: 10px;
  -webkit-box-shadow: 1px 4px 5px 1px rgba(0, 0, 0, 0.21);
  -moz-box-shadow: 1px 4px 5px 1px rgba(0, 0, 0, 0.21);
  box-shadow: 1px 4px 5px 1px rgba(0, 0, 0, 0.21);
}

.clearfix {
  zoom: 1;
}
.cerceve {
  border: 1px solid #ddd;
  overflow: hidden;
  margin: auto;
  padding: 20px;
  width: 100%;
  background-color: #ffffff;
  border-radius: 5px;
}

.green {
  font-family: "Khand", sans-serif;
  font-size: 1.4em;
  color: #00a2e5;
  text-align: center;
}
.wh100percent {
  width: 100%;
}

.right {
  float: right;
}
.textright {
  text-align: right;
}
.left {
  float: left;
}
.size12 {
  font-size: 12px;
}

.fcircle {
  width: 39px;
  height: 39px;
  border: 2px solid #ebebeb;
  background: #fff;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  border-radius: 100px;
  position: relative;
  z-index: 100;
}

span.fdeparture {
  width: 25px;
  height: 21px;
  background: url("/img/departure.png") no-repeat;
  display: block;
  margin: 6px 0 0 4px;
}

span.farrival {
  width: 25px;
  height: 21px;
  background: url("/img/arrival.png") no-repeat;
  display: block;
  margin: 6px 0 0 4px;
}

span.fstop {
  width: 25px;
  height: 21px;
  background: url("/img/stop.png") no-repeat;
  display: block;
  margin: 6px 0 0 4px;
}

.wh33percent {
  width: 33%;
}
.dark {
  color: #333;
}

.fline2px {
  width: 100%;
  height: 2px;
  background: #ebebeb;
  display: block;
  position: relative;
  top: -20px;
  z-index: 10;
}

.theme-payment-page-card-list li {
  cursor: pointer;
}

.nl_image img {
  height: 24px;
  margin-top: -6px;
}
.tab-page {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  text-align: center;
}
.theme-blog-post {
  border-top: 1px solid #46b8da;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.theme-blog-post img{
width: 100%!important;
}
._w-yuze50 {
  width: 50%;
}

.flight-text span {
  padding-top: 5px;
  color: rgba(0, 0, 0, 0.6);

  font-weight: bold;
  display: block;
}

._ph-f-red::-webkit-input-placeholder {
  /* Edge */
  color: red;
}

._ph-f-red:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: red;
}

._ph-f-red::placeholder {
  color: red;
}

.errorRed {
  color: red;
}

.default_olan {
  background-color: #ffffff;
}

.zone_area hr {
  margin-top: -10px !important;
  margin-left: -20px !important;
  border: 3px solid #e6e6e6;
}
.default-page-title {
  text-align: center;
  background-color: #eeeeee;
  padding: 5px;
  margin-bottom: -20px;
}
.visa_text-title {
  text-align: center;
  font-size: 21px;
  font-weight: bold;
  padding-bottom: 15px;
}

.disablingDiv {
  /* Do not display it on entry */
  display: block;

  /* Display it on the layer with index 1001.
       Make sure this is the highest z-index value
       used by layers on that page */
  z-index: 1001;

  /* make it cover the whole screen */
  position: fixed;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;

  /* make it white but fully transparent */
  background-color: black;
  opacity: 0.3;
  filter: alpha(opacity=30);
}

.eskisite {
  font-family: Khand, sans-serif;
  background-color: #0093d2;
  color: #fff;
  position: sticky;
  text-align: center;
  font-weight: bold;
  width: 100%;
  padding: 10px;
  z-index: 9999;
  font-size: 1em;
  border-bottom: 1px solid #0093d2;
}

._office-date-off {
  background-color: #d9534f;
  border-radius: 3px;
  padding: 3px;
  padding-left: 3px;
  margin-top: -4px;
  font-size: 14px;
  font-family: Khand, sans-serif;
  color: #ffffff;
  font-weight: bold;
}

._office-date-on {
  font-family: Khand, sans-serif;

  border-radius: 3px;
  padding: 3px;
  padding-left: 3px;
  margin-top: -4px;
  font-size: 14px;
  color: #ffffff;
  font-weight: bold;
  background-color: #00af64;
}
._lastremember {
  position: relative;
  font-family: Khand, sans-serif;
  font-size: 16px;
  color: #fff;
  background-color: #0093d2;

  border-radius: 3px;
  padding-top: 2px;
  padding-left: 8px;
  padding-right: 26px;
  margin-top: -4px;
}




._bg-remember {
  position: absolute;
  right: 2px;
  top: 2px;
  height: 20px;
  width: 20px;
  text-align: center;
  border-radius: 50px;
  background-color: #d9534f;
  color: #ffffff;
}

.display-none {
  display: none !important;
}

.navbar-theme-border .navbar-nav {
  float: right;
}

._dropdown {
  left: 180px;
  top: 0px;
}

._dropdown li a {
  padding-left: 15px;
}

.srtact {
  color: #0093d2;
  width: 100%;
  text-align: center;
  font-size: 1.5em;
  font-weight: bold;
}
.srtpas {
  color: #737373;
  width: 100%;
  text-align: center;
  font-size: 1.5em;
}

.sifirla_a {
  background-color: transparent !important;
  font-size: 16px !important;
  color: #ffffff !important;
  height: 70px !important;
}

._mh-500{
  min-height: 1000px;
}

.sp-close-modal {
  background: none;
  border: none;
  color: #CDCDCD !important;
  cursor: pointer;
  font-size: 20px;
  font-weight: 600;
  opacity: 0.6;
  position: absolute;
  right: 15px;
  top: 15px;
  transition: opacity 0.3s ease;
  margin: 0;
  padding: 0px;
  z-index: 99999;
}

._close_button{
  position: absolute;
  z-index: 99999;
  right: 0;
  top:0;
  font-size:3em;
 margin-right: 20px;
  float: right;
}
.popup { font-size: 1.0em;
                  }
.popup h5{ font-size: 1.2em;
}
.popup h6{ font-size: 1.1em;
}



@media    (max-height: 668px)  and  (min-height:500px) {
  .popup{ font-size: 0.67em;
   }

  ._border-r-1{
    border-bottom:1px solid #e2e2e2!important;
    border-right:0px solid #e2e2e2!important;
  }

}

@media   (max-height: 814px)  and  (min-height:669px) {
  .popup{ font-size: 0.78em;
     }
  ._border-r-1{
    border-bottom:1px solid #e2e2e2!important;
    border-right:0px solid #e2e2e2!important;
  }
}

._mt-2{margin-top: 2px!important;}

.ben_img{
  width: 28px;
  height: 28px;
  padding: 3px;
  background-color: white;
  border-radius: 5px;
  margin: 0px;
  margin-right: 10px;
}


._br-b-none{
  border-bottom: 0px solid #ffffff!important;
}

.border-gri{
  padding-top: 10px;
  border: 1px solid #00a8f0;
  margin: 10px;
}

._img-40-40{
  width: 35px;
  height: 35px;
  padding: 5px;
}

._tc_button_oturt{


  text-align: right;
  color:#ff6c2d;
  font-weight: bold;
  font-size: 0.9em;

  cursor: pointer;

}
._tc_button_oturt_btn{
background-color: #ff6c2d!important;
  color:white!important;;
  padding-top: 0px!important;;
  padding-bottom: 0px!important;
  border-color: #ff6c2d;
  margin-top: -5px!important;
  cursor: pointer!important;

}
.blog_detail {

border-radius: 3px;
}

.blog_detail img{

  width: 100%;
}

.weather_list{
padding-left: 13px;
}

.weather_list .date{
  font-family: Khand, sans-serif;
  font-size: 14px;
}

.weather_list .w_item{
  width: 12%;
  float: left;
  margin: 0;

  border: solid 1px #d2d2d2;


  background-color: #f2f2f2;
}



.weather_list img{
width: 50%!important;
margin: auto;
  text-align: center;
}

.blog_title{

}
.blog_title h2{
  font-family: Khand, sans-serif;
  font-size: 21px;
  color: #0093d2;
  text-align: center;
  margin:8px;

}
.blog_title h3{
  font-family: Khand, sans-serif;
  font-size: 17px;
  color: #0093d2;
  text-align: center;
  margin:8px;

}
.blog_title p{
  margin:8px;
  text-align: center;
  font-size: 12px;
}
.blog_detail_2{
  height: 500px;

}

.blog_detail_3{
border-radius: 10px;
  height: 260px;
  background-color: #ffffff;
}

.blog_detail_4{

  height: 260px;
}

._bc-red{
  background-color:#ff0000!important;
}

.blogum h1 {
  text-align: center;
  margin: 20px;
  padding-top: 20px;
  font-family: Khand, sans-serif;
  font-size: 28px;
  color: #0093d2;
}
.blog_date{
width: 100%;
  text-align: right;

  font-size: 14px;
  background-color: #ffffff;

  color:#82888a;


}
.author{
color:#82888a;
  font-size: 14px;

}
.date_aut{
  position: relative;
}
.h2_pri{
 font-size:  1.4em;
  padding: 0;
  margin: 10px;
}

.theme-footer-section-list h3 a{ font-size: 0.51em;
color: #000;
opacity:0.4;
padding: 0;
margin: 0;}
.theme-footer-section-list h3 {
  padding: 0;
  margin: 0;}
.theme-footer-section-title{
  font-size: 1em;
}

.shared_link{
  background-color: #f2f2f2;
  padding:10px 5px 5px 20px;
}
.shared_title{

}
.shared_links{
  text-align: right;
}
.shared_links button{

  margin:0 5px 0 0;
}

._f-green{
  color: #5cb85c!important;
}



/* video gallery */


.play-button {
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  height: 60px;
  width: 100px;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 5px;
}

.play-button:hover {
  background-color: rgba(0, 0, 0, 0.9);
}

.play-button:after {
  content: "";
  display: block;
  position: absolute;
  top: 16.5px;
  left: 40px;
  margin: 0 auto;
  border-style: solid;
  border-width: 12.5px 0 12.5px 20px;
  border-color: transparent transparent transparent rgba(255, 255, 255, 1);
}

.close-video::before {
  content: '✖';
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
  font-size: 20px;
  padding: 20px;
  z-index: 1;
  line-height: .7;
  display: block;
  color: #fff;
}
.video-wrapper        {position:relative;}
.video-wrapper .ratio {display:block;width:100%;height:auto;}
.video-wrapper iframe {width:100%; height:500px;}

.mini_bagaj .theme-search-results-item-flight-details-info-stops {
  font-size: 1.1em;
}

.mini_bagaj .theme-search-results-item-flight-details-info-stops  {
  margin: 0;
  margin-bottom: 0!important;
}


.mini_bagaj .theme-search-results-item-flight-details-info-stops img {
width: 22px;
}

.mini_bagaj .theme-search-results-item-flight-details-info-stops .col-md-6 ,.col-xs-6, .col-xs-2{
padding-left: 0;
  padding-right: 0;
}


@media    (min-width:992px) {
  .mini_bagaj .col-xs-offset-4 {
    margin-left: 0;

  }



}

._border-r-1{
  border-bottom-right-radius: 0px!important;
  border-right:1px solid #e2e2e2!important;
}



.t_green{
  color:#4cae4c;
  font-weight: bold;
}
.t_blue{
  color:#0a00d2;
  font-weight: bold;
}
.t_red{
  color:#ff0000;
  font-weight: bold;
}

